<template>
  <div>
    <b-row>
      <b-col
        v-for="item in statisticsItems"
        :key="item.subtitle"
        class="mb-2 mb-md-0"
        cols="4"
      >
        <b-card>
          <b-media no-body>
            <b-media-aside class="mr-2">
              <b-avatar
                size="48"
                :variant="item.color"
              >
                <feather-icon
                  size="24"
                  :icon="item.icon"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h3 
                class="mb-0"
                :class="item.textColor"
                style="font-weight: 800;"
              >
                ฿ {{ item.title | currency }}
              </h3>
              <h5 class="text-muted">
                {{ item.subtitle }}
              </h5>
            </b-media-body>
          </b-media>
        </b-card>
      </b-col>
    </b-row>

    <b-card>
      <b-button
        v-for="menu in menuItems"
        :key="menu.key"
        :variant="menu.key === activeMenu?'primary':'flat-primary'"
        class="mr-1"
        @click="onChangeMenu(menu.key, false)"
      >
        <feather-icon
          :icon="menu.icon"
        />
        {{ menu.title }}
        {{ displayTotalItemCount(menu.key) }}
      </b-button>
    </b-card>

    <component 
      :is="menuComponent" 
      @change-menu="onChangeMenu"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'BillSummary', 
  components: {
    BillsList: () => import('./components/BillsList.vue'),
    InvoicesList: () => import('./components/InvoicesList.vue'),
    PaymentsHistory: () => import('./components/PaymentsHistory.vue'),
  },
  data() {
    return {
      menuItems: [
        {
          key: 'invoices',
          title: 'ยอดที่สรุปแล้ว',
          icon: 'DollarSignIcon',
        },
        {
          key: 'bills',
          title: 'ยอดที่ยังไม่สรุป',
          icon: 'ClockIcon',
        },
        {
          key: 'payments',
          title: 'ประวัติบันทึกการชำระ',
          icon: 'ClipboardIcon',
        },
      ],
      activeMenu: 'bills',
    }
  },
  computed: {
    ...mapGetters(['bills', 'invoices', 'payments']),
    queryTab() {
      const { query } = this.$route
      return query.tab ? query.tab : ''
    },
    totalInvoicesAmount() {
      const initialValue = 0;
      if(this.invoices.length > 0) {
        return this.invoices.map((inv) => +inv?.totalAmount || 0).reduce(
          (previousValue, currentValue) => previousValue + currentValue,
          initialValue
        );
      }
      return initialValue;
    },
    totalBillsAmount() {
      const initialValue = 0;
      if(this.bills.length > 0) {
      return this.bills.map((inv) => +inv?.totalAmount || 0).reduce(
        (previousValue, currentValue) => previousValue + currentValue,
        initialValue
      );
      }
      return initialValue;
    },
    statisticsItems() {
      return [
        {
          icon: 'DollarSignIcon',
          color: 'light-danger',
          textColor: 'text-danger',
          title: this.totalInvoicesAmount,
          subtitle: 'ยอดค้างชำระ',
        },
        {
          icon: 'DollarSignIcon',
          color: 'light-secondary',
          textColor: 'text-secondary',
          title: this.totalBillsAmount,
          subtitle: 'ยอดที่ยังไม่สรุป',
        },
      ]
    },
    menuComponent() {
      switch (this.activeMenu) {
        case 'invoices':
          return 'invoices-list';
        
        case 'bills':
          return 'bills-list';

        case 'payments':
          return 'payments-history';
      
        default:
          return '';
      }
    },
  },
  created() {
    this.fetchData()
    if(this.queryTab){
      this.activeMenu = this.queryTab
    }
  },
  methods: {
    ...mapActions(['getBills', 'getInvoices', 'getPayments']),
    fetchData() {
      this.getBills({
        skip: '', 
        limit: 10,
        search: ''
      });
      this.getInvoices({
        limit: 10,
        skip: '',
        search: '',
        status: ''
      });
      this.getPayments({
        limit: 10,
        skip: '',
        search: ''
      });
    },
    onChangeMenu(menu, isRefetch = true) {
      if(this.activeMenu !== menu){
        this.activeMenu = menu
        this.$addPageQuery('tab', menu)
        if(isRefetch){
          this.fetchData()
        }
      }
    },
    displayTotalItemCount(menu) {
      switch (menu) {
        case 'invoices':
          return this.invoices?.totalItems > 0 ? `(${this.invoices?.totalItems})`: '';
        
        case 'bills':
          return this.bills?.totalItems > 0 ? `(${this.bills?.totalItems})`: '';

        case 'payments':
          return this.payments?.totalItems > 0 ? `(${this.payments?.totalItems})`: '';
      
        default:
          return '';
      }
    },
  },
}
</script>
